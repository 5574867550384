.ck-content
{
    .ck-grid-row
    {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
        grid-gap: 2rem;
        margin-bottom: 1rem;
    }

    .image-left,
    .image-right
    {
        max-width: 100%;
        object-fit: cover;
    }

    .image-left
    {
        float: left;
        margin: 0 1rem 1rem 0;
    }

    .image-right
    {
        float: right;
        margin: 0 0 1rem 1rem;
    }

    .image-center
    {
        max-width: 100%;
        text-align: center;
    }

    img
    {
        max-width: 100%;
        height: auto;
    }

    h3
    {
        font-size: 1.625em;
        color: $colorAccent;
        text-transform: none;
        letter-spacing: 0;
        font-weight: bold;
    }

    h4
    {
        color: $colorAccent;
        margin: 1rem 0 .333rem;
        font-size: 18px;
        letter-spacing: 1px;
    }

    ul, ol
    {
        list-style-position: inside;
        padding-left: 0;
    }

    .table > table
    {
        width: 100%;
    }

    a
    {
        border-bottom-width: 0;
    }

    details
    {
        padding: 0 1rem 1rem;
        border-bottom: 1px solid $colorGrey;
        margin-bottom: 1rem;

        > summary
        {
            font-weight: bold;
            color: $colorAccent;
            list-style: none;
            font-size: 1.25rem;
            outline: none !important;

            &:after
            {
                content: "\f107";
                @include line-awesome();
                float: right;
            }

            &::-webkit-details-marker
            {
                display: none;
            }
        }

        &[open] > summary:after
        {
            content: "\f106";
        }
    }

    blockquote
    {
        text-align: right;
        font-style: italic;
        font-size: 1.25rem;
        line-height: 1.333;
        color: $colorGrey;
        padding-left: 6rem;
        position: relative;

        &:before
        {
            content: "\f10d";
            color: $colorAccent;
            font-style: normal;
            @include line-awesome();
            position: absolute;
            left: 0;
            top: 0;
            font-size: 4rem;
            width: 6rem;
            text-align: left;
            line-height: 0.75;
        }

        > p:last-of-type
        {
            margin-bottom: 0;
        }

        > cite
        {
            color: $colorAccent;
            font-size: .75rem;
            font-style: normal;
            @include uppercaseText();
        }
    }

    .ck-rm-wrapper
    {
        margin-bottom: 1em;

        > .ck-rm-content,
        > .ck-rm-button > .ck-rm-button-open,
        &.open > .ck-rm-button > .ck-rm-button-closed
        {
            display: none;
        }

        &.open > .ck-rm-content
        {
            display: block
        }

        &.open > .ck-rm-button > .ck-rm-button-open
        {
            display: inline;
        }

        > .ck-rm-button
        {
            background-color: $colorAccent;
            color: $colorInverse;
            font-size: .85rem;
            font-style: normal;
            border: 0;
            padding: 1em 4.5em 1em 1.5em;
            line-height: 1;
            position: relative;
            @include uppercaseText();

            &:after
            {
                content: "\f063";
                @include line-awesome();
                display: block;
                padding: 1em;
                background-color: $colorAlternate;
                position: absolute;
                right: 0;
                top: 0;
                box-sizing: content-box;
            }
        }

        &.open > .ck-rm-button
        {
            margin-top: .75rem;

            &:after
            {
                content: "\f062";
            }
        }
    }

    .ck-tab-block .nav-link
    {
        cursor: pointer;
    }

    .ck-cta-wrapper
    {
        display: inline-block;
    }


    .ck-cta-button
    {
        @include arrowButton();
    }
}
